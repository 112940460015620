import React, {useState} from "react";
import {auth, googleProvider} from "../Firebase/firebase";
import {createUserWithEmailAndPassword, signInWithPopup, updateProfile } from "firebase/auth"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import logo from "../../img/localtalent-light.svg";
import AuthDataService from "../../services/auth/authService";
import JobDataService from "../../services/job/jobService";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState({
        signup_uid: "",
        signup_role: "Job Seeker" // Default to Job Seeker, or change if needed
    });

    // Additional state hooks for first and last name
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const signUp = async (e) => {
        e.preventDefault();
        setValidated(true);  // Set validated state before validation check

        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            try {
                // First, create the user with email and password
                const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredentials.user;

                // Ensure the user object is loaded before updating the profile
                if (user) {
                    // Update the user's display name with firstName and lastName
                    await updateProfile(user, {
                        displayName: `${firstName} ${lastName}`,
                    });
                    console.log('User profile updated with display name');
                }

                // Set the signup_uid to the newly created user's uid
                const updatedFormData = {
                    ...formData,
                    signup_uid: user.uid
                };

                // Assuming AuthDataService.createAuthUidRole handles the data saving
                await AuthDataService.createAuthUidRole(updatedFormData);

                console.log("User created:", userCredentials);
                handleClose(); // Close modal after successful sign-up

                // Reload the current screen
                window.location.reload();
            } catch (error) {
                console.error("Error during sign up or profile update:", error);
            }
        }
    };

    return (
        <>
            <Button variant="outline-light" size={'sm'} onClick={handleShow}>
                Create Account
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ borderBottom: "none", background: "#212529" }}></Modal.Header>
                <Modal.Title style={{ textAlign: "center", background: "#212529" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: "50px", width: "auto" }} />
                    </div>
                </Modal.Title>

                <Modal.Body style={{ background: "#212529", borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px", padding: "45px 75px 45px" }}>
                    <Form noValidate validated={validated} onSubmit={signUp}>

                        {/* First Name Field */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{ background: "#212529", color: "white" }}>First Name</InputGroup.Text>
                            <Form.Control
                                style={{ background: "#212529", color: "white" }}
                                type={"text"}
                                id={"signup_firstname"}
                                required={true}
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="John"
                            />
                            {validated && (
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid First Name.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>

                        {/* Last Name Field */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{ background: "#212529", color: "white" }}>Last Name</InputGroup.Text>
                            <Form.Control
                                style={{ background: "#212529", color: "white" }}
                                type={"text"}
                                id={"signup_lastname"}
                                required={true}
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Doe"
                            />
                            {validated && (
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Last Name.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>

                        {/* Email Field */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{ background: "#212529", color: "white" }}>Email</InputGroup.Text>
                            <Form.Control
                                style={{ background: "#212529", color: "white" }}
                                type={"email"}
                                id={"login_email"}
                                required={true}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="johndoe@gmail.com"
                            />
                            {validated && (
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>

                        {/* Password Field */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{ background: "#212529", color: "white" }}>Password</InputGroup.Text>
                            <Form.Control
                                style={{ background: "#212529", color: "white" }}
                                type={"password"}
                                id={"login_password"}
                                required={true}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                            {validated && (
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Password.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>

                        {/* Role (Recruiter/Seeker) Field */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{ background: "#212529", color: "white" }}>Account Type</InputGroup.Text>
                            <Form.Select
                                style={{ background: "#212529", color: "white" }}
                                id={"signup_role"}
                                required={true}
                                name="signup_role"
                                value={formData.signup_role}
                                onChange={handleChange}
                            >
                                <option key={""} value={""}>--Account Type--</option>
                                <option key={"recruiter"} value={"recruiter"}>Recruiter</option>
                                <option key={"seeker"} value={"seeker"}>Job Seeker</option>
                            </Form.Select>
                            {validated && (
                                <Form.Control.Feedback type="invalid">
                                    Please select an account type.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>

                        {/* Submit Button */}
                        <div className={"btn-container"} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button variant="outline-light" type="submit" size={"sm"}>Sign Up</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SignUp;