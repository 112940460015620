// Footer.js
import React from 'react';
import '../../css/Footer.css'; // Make sure to create a corresponding CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            {/* Footer content goes here */}
            <p>© 2023 LocalTalent. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
