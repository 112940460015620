import React, {useContext, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import logo from "../../../img/localtalent-light.svg";
import {Alert, AlertDescription, AlertIcon, AlertTitle, useToast} from "@chakra-ui/react";
import JobService from "../../../services/job/jobService";
import FirebaseContext from "../../Firebase/context";

function ApplyToJob({ jobDetails, companyDetails }) {
    const { currentUser } = useContext(FirebaseContext);

    const [formData, setFormData] = useState({
        // Company Details
        applicant_job_company_name:'',
        // Job Details
        applicant_job_title: '',
        applicant_job_department: '',
        applicant_job_location: '',
        applicant_email_to: '',
        // Applicant Details
        applicant_first_name: '',
        applicant_last_name: '',
        applicant_phone: '',
        applicant_linkedinProfile:'',
        applicant_portfolio: '',
        applicant_resume: '',
        applicant_cv: '',
        applicant_user_uid: !currentUser ? [] : [currentUser.uid] // Always an array
    });

    const [resumeFile, setResumeFile] = useState(null); // New state to hold the logo file

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [notLogged, setNotLogged] = useState(false);

    const toast = useToast()

    const [errors, setErrors] = useState({});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!currentUser) {
            setNotLogged(true);
        } else {
            setNotLogged(false);
        }
    }, [currentUser]);

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData, [name]: value || '', // Ensure empty values are set correctly
        }));
    }

    // Handle resume separately
    function handleResumeChange(event) {
        const file = event.target.files[0];
        setResumeFile(file); // Save the file to the resumeFile state
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isFormValid = validateFormData(formData);

        if (Object.keys(isFormValid).length > 0) {
            setErrors(isFormValid);
            e.stopPropagation();
            return;
        }

        // Hide errors afterwards
        setErrors('');

        if (!resumeFile) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                applicant_resume: "Please provide a valid resume file"
            }));
            return;
        }

        // Hide errors afterwards
        setErrors('');

        if (notLogged) {
            e.stopPropagation();
        } else {
            const promise = new Promise(async (resolve, reject) => {
                try {
                    const formDataObj = new FormData();
                    formDataObj.append('applicant_job_company_name', companyDetails.company_name);
                    formDataObj.append('applicant_job_title', jobDetails.job_title);
                    formDataObj.append('applicant_job_department', jobDetails.job_department);
                    formDataObj.append('applicant_job_location', jobDetails.job_location);
                    formDataObj.append('applicant_email_to', jobDetails.job_apply_link);
                    formDataObj.append('applicant_first_name', formData.applicant_first_name);
                    formDataObj.append('applicant_last_name', formData.applicant_last_name);
                    formDataObj.append('applicant_phone', formData.applicant_phone);
                    formDataObj.append('applicant_linkedinProfile', formData.applicant_linkedinProfile);
                    formDataObj.append('applicant_portfolio', formData.applicant_portfolio);
                    formDataObj.append('applicant_resume', resumeFile);  // Append the file here
                    formDataObj.append('applicant_cv', '');
                    formDataObj.append('applicant_user_uid', formData.applicant_user_uid);

                    await JobService.jobApplicationEmail(formDataObj);
                    resolve();
                } catch (error) {
                    console.error('Error submitting application:', error);
                    reject(error);
                }
            });

            toast.promise(promise, {
                loading: { title: 'Submitting application...', description: 'Please wait' },
                success: { title: 'Application submitted successfully!', description: 'Operation successful' },
                error: { title: 'Error submitting application', description: 'Please try again' }
            });
        }
    };

    function validateFormData(formData) {
        const errors = {};

        for (let key in formData) {
            // Skip validation for applicant_job_company_name
            if (key === 'applicant_job_company_name') continue;

            // Skip validation for applicant_job_title
            if (key === 'applicant_job_title') continue;

            // Skip validation for applicant_job_department
            if (key === 'applicant_job_department') continue;

            // Skip validation for applicant_job_location
            if (key === 'applicant_job_location') continue;

            // Skip validation for applicant_user_uid
            if (key === 'applicant_user_uid') continue;

            // Skip validation for applicant_linkedinProfile
            if (key === 'applicant_linkedinProfile') continue;

            // Skip validation for applicant_portfolio
            if (key === 'applicant_portfolio') continue;

            // Skip validation for applicant_cv
            if (key === 'applicant_cv') continue;

            if (!formData[key] && key !== 'applicant_resume') {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }
        }

        return errors;
    }
    
    return (
        <>
            <div className={"btn-container"} style={{display: "flex", alignItems: "center", justifyContent: "center", padding: "20px"}}>
                <Button variant={"outline-light"} size={"lg"} onClick={handleShow}>
                    Apply
                </Button>
            </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{background: "#212529", color: "white"}}>
                        <div className={"signInLogo"} style={{textAlign: "center", background: "#212529"}}>
                            <img src={logo} alt="Logo" style={{maxHeight: "50px", width: "auto"}}/>
                        </div>
                        <Modal.Title>Job Application for <strong>{jobDetails.job_title}</strong></Modal.Title>
                    </Modal.Header>
                    {notLogged ?
                        <Row>
                            <Col>
                                <Alert status='error' variant={"left-accent"}>
                                    <AlertIcon />
                                    <AlertTitle>You need to log in!</AlertTitle>
                                    <AlertDescription>Please login before applying any job.</AlertDescription>
                                </Alert>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                    <Modal.Body style={{background: "#212529"}}>
                        <Form noValidate>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        <strong>First Name</strong>
                                        <br/><small>This field is required.</small>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_first_name::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_first_name"}
                                            required={true}
                                            name="applicant_first_name"
                                            value={formData.applicant_first_name}
                                            onChange={handleChange}
                                            placeholder="John"
                                            aria-label="applicant_first_name"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {errors.applicant_first_name &&
                                        <p className={"field-required"}>Please provide a valid First Name</p>}
                                </Col>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        <strong>Last Name</strong>
                                        <br/><small>This field is required.</small>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_last_name::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_last_name"}
                                            required={true}
                                            name="applicant_last_name"
                                            value={formData.applicant_last_name}
                                            onChange={handleChange}
                                            placeholder="Doe"
                                            aria-label="applicant_last_name"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {errors.applicant_last_name &&
                                        <p className={"field-required"}>Please provide a valid Last Name</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        <strong>Email Address</strong>
                                        <br/><small>This field is required.</small>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_email_to::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_email_to"}
                                            required={true}
                                            name="applicant_email_to"
                                            value={formData.applicant_email_to}
                                            onChange={handleChange}
                                            placeholder="johndoe@gmail.com"
                                            aria-label="applicant_email_to"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {errors.applicant_email_to &&
                                        <p className={"field-required"}>Please provide a valid Email</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        <strong>Phone Number</strong>
                                        <br/><small>This field is required.</small>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_phone::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_phone"}
                                            required={true}
                                            name="applicant_phone"
                                            value={formData.applicant_phone}
                                            onChange={handleChange}
                                            placeholder="7871234567"
                                            aria-label="applicant_phone"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {errors.applicant_phone &&
                                        <p className={"field-required"}>Please provide a valid Phone Number</p>}
                                </Col>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        LinkedIn Profile
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_linkedinProfile::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_linkedinProfile"}
                                            required={false}
                                            name="applicant_linkedinProfile"
                                            value={formData.applicant_linkedinProfile}
                                            onChange={handleChange}
                                            placeholder="LinkedIn Profile URL"
                                            aria-label="applicant_linkedinProfile"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {/*{errors.applicant_linkedinProfile && <p className={"field-required"}>Please provide a valid location</p>}*/}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        Portfolio URL
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_portfolio::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                        <Form.Control
                                            style={{background: "#212529", color: "white"}}
                                            id={"applicant_portfolio"}
                                            required={false}
                                            name="applicant_portfolio"
                                            value={formData.applicant_portfolio}
                                            onChange={handleChange}
                                            placeholder="Portfolio URL"
                                            aria-label="applicant_portfolio"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    {/*{errors.applicant_portfolio && <p className={"field-required"}>Please provide a valid location</p>}*/}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        <strong>Resume</strong>
                                        <br/><small>This field is required.</small>
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_resume::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Logo</InputGroup.Text>*/}
                                        <Form.Control
                                            type="file" // Changed to file input
                                            style={{background: "#212529", color: "white"}}
                                            id="applicant_resume"
                                            name="applicant_resume"
                                            onChange={handleResumeChange} // Handle file change
                                            aria-label="applicant_resume"
                                        />
                                    </InputGroup>
                                    {errors.applicant_resume &&
                                        <p className={"field-required"}>Please provide a valid resume file</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{background: "#212529", color: "white"}}>
                                        CV
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <style type="text/css">
                                            {`
                                            #applicant_cv::placeholder {
                                                color: #888; /* Change this to your desired placeholder color */
                                            }
                                        `}
                                        </style>
                                        {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Logo</InputGroup.Text>*/}
                                        <Form.Control
                                            type="file" // Changed to file input
                                            style={{background: "#212529", color: "white"}}
                                            id="applicant_cv"
                                            name="applicant_cv"
                                            // onChange={handleLogoChange} // Handle file change
                                            aria-label="applicant_cv"
                                        />
                                    </InputGroup>
                                    {/*{errors.company_logo && <p className={"field-required"}>Please provide a valid company logo</p>}*/}
                                </Col>
                            </Row>
                            <Modal.Footer style={{background: "#212529"}}>
                                <Button variant="outline-danger" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="outline-light" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
            );
            }

            export default ApplyToJob;