import React, {useState, useEffect, useContext} from "react";
import JobDataService from "../../services/job/jobService";
import { Col, Row, Container, Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareCheck,
    faCaretRight,
    faCaretLeft,
    faLocationDot,
    faBuilding
} from '@fortawesome/free-solid-svg-icons'
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import '../../css/Divider.css'
import MoreJobDetails from "../Feed/jobs/MoreJobDetails";
import Collapse from 'react-bootstrap/Collapse';
import {Alert, AlertIcon, AlertTitle} from "@chakra-ui/react";
import LoadingScreenFeed from "../Basic/LoadingScreenFeed";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../constants/routes";
import {JobDepartment, JobExpLevel, JobSalary, JobType} from "../Feed/jobs/buildJobItems/JobItems";
import FirebaseContext from "../Firebase/context";


function UserJobsList({ currentPage }) {
    const [jobs, setJobs] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    const [idOfJob, setIdOfJob] = useState("");
    const [open, setOpen] = useState(false);
    const [emptyArray, setIsEmptyArray] = useState(false);
    const navigate = useNavigate();

    const { currentUser } = useContext(FirebaseContext);

    // New state to track if job details are shown
    const [showDetails, setShowDetails] = useState({});


    useEffect(() => {
        if (!currentUser) {
            navigate(HOME);
        } else {
            retrieveUserJobs(); // Pass uid directly to the function
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, [currentUser, currentPage]); // Fixed typo here

    const retrieveUserJobs = async () => {
        setIsLoading(true);
        try {
            let response = await JobDataService.fetchUserJobs({ job_uid: currentUser.uid, currentPage: currentPage }); // Ensure this matches your expected parameter structure
            setJobs(response.data.userJobs);

            if(!Array.isArray(response.data.userJobs) || !response.data.userJobs.length) {
                setIsEmptyArray(true);
            } else {
                setIsEmptyArray(false); // Reset this state if there are jobs
            }

            setIsLoading(false);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    };

    function calculatePreciseDaysBetweenDates(startDate) {
        const today = new Date();
        const start = new Date(startDate);
        const timeDiff = today - start; // Difference in milliseconds
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff;
    }

    const handleSelectChange = (id) => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        if (idOfJob === id) {
            // Set job detail tab to not open
            setOpen(!open);
            // Toggle the showDetails state for this job ID
            setShowDetails(prevState => ({...prevState, [id]: !prevState[id]}));
        } else {
            // Handle opening new
            setIdOfJob(id);

            // set job detail tab to open
            setOpen(true);

            // Set only the current job's details to true and reset others
            setShowDetails(prevState => {
                const resetState = Object.keys(prevState).reduce((acc, key) => {
                    acc[key] = false; // Reset all to false
                    return acc;
                }, {});
                return {...resetState, [id]: true};
            });
        }
    };


    return (
        <div className="App">
            <Container>
                {isLoading ? (
                    <LoadingScreenFeed feed={"job"}/>
                    )
                    : (
                        emptyArray ?
                            <Row>
                                <Col>
                                    <Alert status='info'
                                           flexDirection='column'
                                           alignItems='center'
                                           justifyContent='center'
                                           textAlign='center'>
                                        <AlertIcon boxSize='40px' mr={0} />
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                                            Results Info
                                        </AlertTitle>
                                        No jobs found.
                                    </Alert>
                                </Col>
                            </Row>
                            :
                        <Row>
                            <Col>
                                {jobs.map((jobs) => {
                                    let josPosted = jobs.job_posted_on;
                                    const message = calculatePreciseDaysBetweenDates(josPosted) < 1
                                        ? "less than a day ago"
                                        : Math.floor(calculatePreciseDaysBetweenDates(josPosted)) + " days ago";

                                    return (
                                        <Row key={jobs._id}>
                                            <Card style={{ width: "85%", margin: 'auto', marginBottom: "30px", borderRadius:"15px"}} bg={"dark"}>
                                                <Card.Body style={{color: "white", textAlign:isMobile ? "start" : ""}}>
                                                    <Row>
                                                        <Col md={4} style={{margin:"auto"}}>
                                                            <Card.Title className={"mb-2"} style={{color:"#cf6a4c"}}>
                                                                <h3><strong>{jobs.job_title}</strong></h3>
                                                            </Card.Title>
                                                            <Card.Subtitle className="mb-2">
                                                                <OverlayTrigger
                                                                    trigger="hover"
                                                                    key={"companyDetails_company"}
                                                                    placement={"auto"}
                                                                    overlay={
                                                                        <Popover id={`popover-positioned-right`}>
                                                                            <Popover.Header as="h3">{`Company Profile`}</Popover.Header>
                                                                            <Popover.Body>
                                                                            <span>
                                                                                Redirect me to <b>{jobs.companyDetails.company_name}</b> profile page.
                                                                            </span>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                            <span>
                                                                <h4><FontAwesomeIcon icon={faBuilding} /> <u><Link to={"/company/" + jobs.companyDetails._id}>{jobs.companyDetails.company_name}</Link></u></h4>
                                                            </span>
                                                                </OverlayTrigger>
                                                            </Card.Subtitle>
                                                            <Card.Subtitle className="mb-2">
                                                                <h6>
                                                                    {jobs.job_location} <FontAwesomeIcon icon={faLocationDot} />
                                                                </h6>
                                                            </Card.Subtitle>
                                                        </Col>
                                                        <Col md={4} style={{margin:"auto"}}>
                                                            <div style={{textAlign:isMobile ? "start" : "center", padding:isMobile ? "0 0 10px 0" : "10px", paddingTop:"0px"}}>
                                                                <FontAwesomeIcon icon={faSquareCheck} /> <strong><u>Key Features</u></strong>
                                                            </div>
                                                            <Card.Subtitle className="mb-2">
                                                                <JobDepartment jobs={jobs} />
                                                                <JobExpLevel jobs={jobs}/>
                                                                <JobType jobs={jobs}/>
                                                                <JobSalary jobs={jobs}/>
                                                            </Card.Subtitle>
                                                        </Col>
                                                        { isMobile ? "" : <div className="divider"></div>}
                                                        <Col md={2} style={{margin:"auto"}}>
                                                            <Card.Text>
                                                                {isMobile ?
                                                                    <Button as={Link} to={"/job/" + jobs._id} variant={"outline-light"} style={{background:"#cc5500"}}>
                                                                        <strong>Job Detail <FontAwesomeIcon icon={faCaretRight} /></strong>
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        variant={"outline-light"}
                                                                        style={showDetails[jobs._id] ? {background:"#4e5358"} : {background:"#cc5500"}}
                                                                        onClick={() => handleSelectChange(jobs._id)}
                                                                    >
                                                                        {showDetails[jobs._id] ? <strong>Hide Detail <FontAwesomeIcon icon={faCaretLeft} /></strong> : <strong>Job Detail <FontAwesomeIcon icon={faCaretRight} /></strong>}
                                                                    </Button>
                                                                }
                                                            </Card.Text>
                                                            <Card.Text>
                                                                <Button as={Link} to={"../update/job/" + jobs._id} variant={"outline-light"} style={{background:"#cc5500"}}>
                                                                    <strong>Update Job <FontAwesomeIcon icon={faCaretRight} /></strong>
                                                                </Button>
                                                            </Card.Text>
                                                            <Card.Text>
                                                                <div style={{fontSize:"small", float: isMobile ? "":"center", marginTop:"10px"}}>
                                                                    Posted {message}
                                                                </div>
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    );
                                })}
                            </Col>
                            {isMobile ?
                                ""
                                :
                                <Collapse in={open}>
                                    <Col>
                                        <MoreJobDetails id={idOfJob} />
                                    </Col>
                                </Collapse>
                            }
                        </Row>
                    )}
            </Container>
        </div>
    );
};
export default UserJobsList;
