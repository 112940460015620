import axios from "axios";

class companyDataService {
    async getAllCompanies(page = 0) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/all?page=${page}`;

            // Send Request
            const response = await axios.get(baseURL);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async getUserCompanies(uid) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/my-company`;

            // Send Request
            const response = await axios.post(baseURL, uid);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchCompanyById(id) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/company/${id}`;

            // Send Request
            const response = await axios.get(baseURL);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async createCompany(form) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/new/company`;

            // Send Request
            await axios.post(baseURL, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async updateCompany(id, form) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/update/company/${id}`;

            // Send Request
            await axios.put(baseURL, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    // Not used for now
    async deleteCompany(id) {
        try {
            await axios.delete(`https://localtalent-backend.onrender.com/companies/delete/company/${id}`);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async validateCompanyProdileExistsForUser(uid) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/validate`;

            // Send Request
            const companyProfileExist = await axios.post(baseURL, uid);
            return companyProfileExist;
        } catch (error) {
            console.error("An error occurred while checking if company profile exists for user: " + error);
        }
    }

    async requestJoiningCompanies({ company_id, company_name, company_email, user_displayName, user_email, user_uid }) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/join-request`;

            // Send Request
            const joinCompany = await axios.post(baseURL, {
                company_id,
                company_name,
                company_email,
                user_displayName,
                user_email,
                user_uid
            });
            return joinCompany;
        } catch (error) {
            console.error("An error occurred requesting to join company: " + error);
        }
    }

    async joinCompanyApproval(company_id, uid, action) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/companies/approve?company_id=${company_id}&uid=${uid}&action=${action}`;

            // Send Request
            const joinCompany = await axios.get(baseURL);
            return joinCompany;
        } catch (error) {
            console.error("An error occurred requesting to join company: " + error);
            throw error; // Re-throw the error so it can be handled by the calling function
        }
    }
}

export default new companyDataService();