import React from 'react';

function CompanyList({ company }) {
    return (
        <div style={{ paddingBottom: "20px" }}>
            {company.company_logo ? (
                <img src={company.company_logo} alt={`${company.company_name} logo`} style={{ maxHeight: '100px', borderRadius: '25px' }} />
            ) : (
                <p>No logo available</p>
            )}
        </div>
    );
}

export default CompanyList;