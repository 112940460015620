import CompanyDataService from "../../../../services/company/companyService";
import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFaceSmile,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import {Alert, AlertDescription, AlertIcon, AlertTitle, useToast} from "@chakra-ui/react";
import FirebaseContext from "../../../Firebase/context";

const HandleJoinRequest = ({ companies }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const toast = useToast();
    const [notLogged, setNotLogged] = useState(false);

    const { currentUser } = useContext(FirebaseContext);

    useEffect(() => {
        if (!currentUser) {
            setNotLogged(true);
        } else {
            setNotLogged(false);
        }
    }, [currentUser]);

    const handleJoinRequest = async () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                let response = await CompanyDataService.requestJoiningCompanies({
                    company_id: companies._id,
                    company_name: companies.company_name,
                    company_email: companies.company_email,
                    user_displayName: currentUser.displayName,
                    user_email: currentUser.email,
                    user_uid: currentUser.uid,
                });
                resolve();
            } catch (error) {
                console.error('Error sending join request:', error);
                reject(error);
            }
            setShowConfirmation(false);
        });

        toast.promise(promise, {
            loading: {
                title: 'Sending Your Request...',
                description: 'Please wait while we process your request.'
            },
            success: {
                title: 'Request Sent Successfully!',
                description: `Your request to join ${companies.company_name} has been sent.`
            },
            error: {
                title: 'Request Failed',
                description: `An error occurred while sending your request to ${companies.company_name}. Please try again later.`
            }
        });
    };

    return (
        <Card key={companies._id} style={{ margin: "auto", borderRadius: "15px", color:"white"}} bg={"dark"}>
            <Card.Body>
                {notLogged ?
                    <Row style={{marginBottom:"20px"}}>
                        <Col>
                            <Alert status='error' variant={"left-accent"} textColor={"black"}>
                                <AlertIcon />
                                <AlertTitle>You need to log in!</AlertTitle>
                                <AlertDescription>Please sign in to request to join {companies.company_name} company profile.</AlertDescription>
                            </Alert>
                        </Col>
                    </Row>
                    :
                    ""
                }
                <Card.Title>
                    <h2>
                        <FontAwesomeIcon icon={faUserPlus} /> Employed by {companies.company_name}?
                    </h2>
                </Card.Title>
                <Card.Text>
                    <div>
                        If you are an employee of this company but have not yet joined their company group,
                        please click the button below to request access <FontAwesomeIcon icon={faFaceSmile} />
                    </div>
                </Card.Text>
                {notLogged ?
                    ""
                    :
                    <div>
                        <Card.Text>
                            <Button size={"sm"} variant={"outline-light"} style={{background: "#cc5500"}}
                                    onClick={() => setShowConfirmation(true)}><b>Request to join?</b></Button>
                        </Card.Text>
                        <Collapse in={showConfirmation}>
                            <div className="confirmation-dialog">
                                <p>Please confirm that you wish to request access to join
                                    the <b>{companies.company_name}</b> company group.</p>
                                <Row sm={6} className="justify-content-center">
                                    <Col>
                                        <Button variant="outline-light" size={"sm"}
                                                onClick={handleJoinRequest}>Confirm</Button>
                                    </Col>
                                    <Col>
                                        <Button variant="outline-danger" size={"sm"}
                                                onClick={() => setShowConfirmation(false)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                    </div>
                }
            </Card.Body>
        </Card>
    );
};

export default HandleJoinRequest;