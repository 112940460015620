import React, {useContext, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import JobDataService from "../../../services/job/jobService";
import logo from "../../../img/localtalent-light.svg";
import { Col, Row } from "react-bootstrap";
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import companyDataService from "../../../services/company/companyService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faExclamation
} from '@fortawesome/free-solid-svg-icons'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import { Switch, FormLabel } from '@chakra-ui/react'
import UserCompany from "../../User/UserCompany";
import '../../../css/ErrorHandling.css'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FirebaseContext from "../../Firebase/context";

function JobForm() {
    const { currentUser } = useContext(FirebaseContext);

    const [formData, setFormData] = useState({
        job_title: '',
        job_company_id: '',
        job_location: '',
        job_department:'',
        job_exp_level: '',
        job_apply_link: '',
        job_type: '',
        job_salary_range: '',
        job_description: '',
        job_posted_on: new Date(),
        job_uid: !currentUser ? "" : currentUser.uid
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [notLogged, setNotLogged] = useState(false);

    const toast = useToast()

    const navigate = useNavigate();

    const [companies, setCompanies] = useState([]);

    const [disableSalary, setDisableSalary] = useState(false); // State to handle the switch

    const [errors, setErrors] = useState({});

    const toggleSalaryInput = () => {
        setDisableSalary(!disableSalary); // Toggle the state to enable/disable salary input
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isFormValid = validateFormData(formData, disableSalary);

        // Check if there are any errors
        if (Object.keys(isFormValid).length > 0) {
            setErrors(isFormValid); // Set the errors to state
            e.stopPropagation();
            return; // Prevents the rest of function from running
        }

        if (notLogged) {
            e.stopPropagation();
        } else {
            const promise = new Promise(async (resolve, reject) => {
                try {
                    await JobDataService.createJob(formData);
                    resolve();
                    toast({
                        title: 'Redirecting to Jobs Feed',
                        description: 'In about 5 seconds',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    setTimeout(() => navigate('/'), 5000); // Delay navigation by 5 seconds
                } catch (error) {
                    console.error('Error creating job:', error);
                    reject(error);
                }
            });

            toast.promise(promise, {
                loading: { title: 'Creating job...', description: 'Please wait' },
                success: { title: 'Job created successfully!', description: 'Operation successful' },
                error: { title: 'Error creating job', description: 'Please try again' }
            });
        }
    };

    function validateFormData(formData, disabledSalary) {
        const errors = {};

        for (let key in formData) {
            // Skip validation for company_user_uid
            if (key === 'job_uid') continue;

            // Skip validation for job_salary_range if salary is disabled
            if (key === 'job_salary_range' && disableSalary) continue;

            // Validate Select Fields
            if (key === 'job_company_id' && FormData[key] === "") {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }

            if (key === 'job_exp_level' && FormData[key] === "") {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }

            if (key === 'job_type' && FormData[key] === "") {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }

            // Set validation error
            if (!formData[key]) {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }
        }
        return errors;
    }

    useEffect(() => {
        if (!currentUser) {
            setNotLogged(true);
        } else {
            setNotLogged(false);
        }

        const fetchCompanies = async () => {
            try {
                let response = await companyDataService.getUserCompanies({company_user_uid: currentUser.uid});
                setCompanies(response.data.userCompanies);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, [currentUser]);

    return (
        <>
            <div style={{width: isMobile ? "90%" : "70%", margin:"auto", padding:"20px"}}>
                <Form noValidate onSubmit={handleSubmit} style={{background: "#212529", padding:"50px", borderRadius:"25px"}}>
                    <div className={"signInLogo"} style={{textAlign: "center", background: "#212529"}}>
                        <img src={logo} alt="Logo" style={{maxHeight: "50px", width: "auto"}}/>
                    </div>
                    <div style={{textAlign: "center", color: "white"}}>
                        <h2><strong>Job Form</strong></h2>
                    </div>
                    {/*
                        This checks if the user has previously created a Company Profile
                    */}
                    {!currentUser ?
                        ""
                    :
                        <UserCompany uid={currentUser.uid} authUser={currentUser}/>
                    }
                    {notLogged ?
                        <Row style={{marginBottom:"20px"}}>
                            <Col>
                                <Alert status='error' variant={"left-accent"}>
                                    <AlertIcon />
                                    <AlertTitle>You need to log in!</AlertTitle>
                                    <AlertDescription>Please login before posting a new job.</AlertDescription>
                                </Alert>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                    <Row>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Company</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_company_id::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Company</InputGroup.Text>*/}
                                <Form.Select
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_company_id"}
                                    required={true}
                                    name="job_company_id"
                                    value={formData.job_company_id}
                                    onChange={handleChange}
                                    placeholder="LocalTalent LLC"
                                    aria-label="job_company_id"
                                    aria-describedby="basic-addon1"
                                >
                                    <option key={""} value={""}>--Select a Company--</option>
                                    {companies.map(company => (
                                        <option key={company._id} value={company._id}>{company.company_name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                            {errors.job_company_id && <p className={"field-required"}>Please select a valid company</p>}
                        </Col>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Job Title</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_title::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Job Title</InputGroup.Text>*/}
                                <Form.Control
                                    id={"job_title"}
                                    required={true}
                                    name="job_title"
                                    value={formData.job_title}
                                    onChange={handleChange}
                                    placeholder="Node JS Dev"
                                    aria-label="job_title"
                                    aria-describedby="basic-addon1"
                                    style={{background:"#212529", color:"white"}}
                                />
                            </InputGroup>
                            {errors.job_title && <p className={"field-required"}>Please provide a valid job title</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Location</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_location::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_location"}
                                    required={true}
                                    name="job_location"
                                    value={formData.job_location}
                                    onChange={handleChange}
                                    placeholder="Puerto Rico"
                                    aria-label="job_location"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.job_location && <p className={"field-required"}>Please provide a valid location</p>}
                        </Col>
                        <Col md={3}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Department</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_department::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Department</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_department"}
                                    required={true}
                                    name="job_department"
                                    value={formData.job_department}
                                    onChange={handleChange}
                                    placeholder="IT"
                                    aria-label="job_department"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.job_department && <p className={"field-required"}>Please provide a valid department</p>}
                        </Col>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Experience Level</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_exp_level::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Experience Level</InputGroup.Text>*/}
                                <Form.Select
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_exp_level"}
                                    required={true}
                                    name="job_exp_level"
                                    value={formData.job_exp_level}
                                    onChange={handleChange}
                                    placeholder="Senior"
                                    aria-label="job_exp_level"
                                    aria-describedby="basic-addon1"
                                >
                                    <option key={""} value={""}>--Select Experience Level--</option>
                                    <option key={"intern"} value={"intern"}>Internship</option>
                                    <option key={"jr"} value={"jr"}>Entry/Jr. Level</option>
                                    <option key={"mid"} value={"mid"}>Mid Level</option>
                                    <option key={"senior"} value={"senior"}>Senior Level</option>
                                </Form.Select>
                            </InputGroup>
                            {errors.job_exp_level && <p className={"field-required"}>Please select a valid experience level</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Apply Link</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_apply_link::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Apply Link</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_apply_link"}
                                    required={true}
                                    name="job_apply_link"
                                    value={formData.job_apply_link}
                                    onChange={handleChange}
                                    placeholder="https://www.localtalent.me/jobs"
                                    aria-label="job_apply_link"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.job_apply_link && <p className={"field-required"}>Please provide a valid apply link</p>}
                        </Col>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Job Type</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #job_type::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Job Type</InputGroup.Text>*/}
                                <Form.Select
                                    style={{background:"#212529", color:"white"}}
                                    id={"job_type"}
                                    required={true}
                                    name="job_type"
                                    value={formData.job_type}
                                    onChange={handleChange}
                                    placeholder="Full-Time, Part-Time"
                                    aria-label="job_type"
                                    aria-describedby="basic-addon1"
                                >
                                    <option key={""} value={""}>--Select Job Type--</option>
                                    <option key={"full"} value={"full"}>Full-Time</option>
                                    <option key={"part"} value={"part"}>Part-Time</option>
                                </Form.Select>
                            </InputGroup>
                            {errors.job_type && <p className={"field-required"}>Please select a valid job type</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Salary</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {disableSalary ?
                                        `
                                        #job_salary_range::placeholder {
                                            color: yellow; /* Change this to your desired placeholder color */
                                        }
                                        `
                                        :
                                        `
                                        #job_salary_range::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                        `
                                    }
                                </style>
                                <InputGroup.Text style={{background: "#212529", color: "white"}}>
                                    <Switch
                                        type="switch"
                                        id="disable-salary-switch"
                                        checked={disableSalary}
                                        onChange={toggleSalaryInput}
                                    />
                                    <span style={{marginLeft: "10px"}}>
                                        Disable Salary
                                    </span>
                                </InputGroup.Text>
                                <Form.Control
                                    style={{background: "#212529", color: disableSalary ? "yellow" : "white"}}
                                    id="job_salary_range"
                                    required={!disableSalary} // Make field required only if the switch is not true
                                    name="job_salary_range"
                                    value={disableSalary ? "Do not specify salary" : formData.job_salary_range}
                                    onChange={handleChange}
                                    placeholder={disableSalary ? "Do not specify salary" : "$60,000/year or $25/hour"}
                                    aria-label="job_salary_range"
                                    aria-describedby="basic-addon1"
                                    disabled={disableSalary} // Disable based on the switch state
                                />
                            </InputGroup>
                            {disableSalary ?
                                <InputGroup className="mb-3">
                                <span style={{color:"#F5F5DC", border:"solid", padding:"10px", width:"100%", textAlign:"center"}}>
                                    <FontAwesomeIcon icon={faCircleExclamation} /> Jobs with listed salaries attract more qualified candidates and receive 30% more applications. Add a salary to boost your job post’s visibility and success <FontAwesomeIcon icon={faExclamation} />
                                </span>
                                </InputGroup>
                                : ""
                            }
                            {errors.job_salary_range && <p className={"field-required"}>Please provide a valid salary</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Label style={{background: "#212529", color: "white"}}>Job Description</Form.Label>
                            <InputGroup className="mb-3">
                                <div style={{width: '100%'}}>
                                    {/*<InputGroup.Text style={{background: "#212529", color: "white"}}>Job Description</InputGroup.Text>*/}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.job_description}
                                        onChange={(event, editor) => {
                                            const content = editor.getData();
                                            handleChange({target: {name: 'job_description', value: content}});
                                        }}
                                        config={{
                                            placeholder: "Type in the mission & values for this company...",
                                            toolbar: ['undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList'],
                                        }}
                                    />
                                </div>
                            </InputGroup>
                            {errors.job_description && <p className={"field-required"}>Please provide a valid despription</p>}
                        </Col>
                    </Row>
                    <div className={"btn-container"}
                         style={{display: "flex", alignItems: "center", justifyContent: "center", padding: "20px"}}>
                        <Button variant="outline-light" type="submit" style={{textAlign: "center"}}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default JobForm;
