import CompanyServiceData from "../../services/company/companyService";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NEW_COMPANY } from "../../constants/routes";
import { Container } from "react-bootstrap";
import {Alert, AlertDescription, AlertIcon, AlertTitle} from "@chakra-ui/react";

function UserCompany({ authUser, uid }) {
    const navigate = useNavigate();
    const [companyProfileExist, setCompanyProfileExist] = useState(null); // Assuming boolean value needed
    const [countdown, setCountdown] = useState(10); // Set initial countdown value to 10 seconds

    useEffect(() => {
        const validateCompanyProfileExist = async (uid) => {
            try {
                let response = await CompanyServiceData.validateCompanyProdileExistsForUser({ company_user_uid: uid });
                setCompanyProfileExist(response.data.companyProfileExistsForUser);
            } catch (error) {
                console.error("Error validating company profile existence:", error);
            }
        };

        // Only run if user is logged in
        if (uid) {
            validateCompanyProfileExist(uid);
        }

    }, [uid]); // Add uid to the dependency array if it's expected to change

    // Use another useEffect to observe changes to resumeExist
    useEffect(() => {
        let timer;
        if (companyProfileExist !== null) {
            if (!companyProfileExist) {
                timer = setInterval(() => {
                    setCountdown((currentCountdown) => {
                        if (currentCountdown <= 1) {
                            clearInterval(timer);
                            navigate(NEW_COMPANY);
                            return 0;
                        }
                        return currentCountdown - 1;
                    });
                }, 1000);
            }
        }
    }, [companyProfileExist, navigate]);

    // Render animation while it checks to where to redirect
    if (!companyProfileExist && authUser) {
        return (
            <div className={"App"}>
                <Container style={{padding:"0px 0px 10px 0px", width:"75%", fontSize:"small"}}>
                    <Alert status='warning' variant={"left-accent"} textAlign={"center"}>
                        <AlertIcon />
                        <AlertTitle>Missing!</AlertTitle>
                        <AlertDescription>
                            Seems like you are missing a <u>company profile</u>. We'll re-directed you in <b>{countdown}</b> seconds.
                        </AlertDescription>
                    </Alert>
                </Container>
            </div>
        )
    }
}

export default UserCompany;
