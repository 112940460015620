import React, { useState, useEffect } from "react";
import JobDataService from "../../../services/job/jobService";
import { Col, Row, Container, Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import {Link, useNavigate, useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareCheck,
    faCircleInfo,
    faBuilding,
    faLocationDot
} from '@fortawesome/free-solid-svg-icons'
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DOMPurify from 'dompurify';
import LoadingScreenIndividual from "../../Basic/LoadingScreenIndividual";
import {JobDepartment, JobExpLevel, JobSalary, JobType} from "./buildJobItems/JobItems";
import ApplyToJob from "./ApplyToJob";

function MoreJobDetails({ id: propId }) {
    const [job, setJob] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    // Determine the source of 'id'
    const { id: routeId } = useParams();
    const effectiveId = propId || routeId;
    const navigate = useNavigate();

    useEffect(() => {
        retrieveJob();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, [propId]);

    const retrieveJob = async () => {
        setIsLoading(true);
        try {
            let response = await JobDataService.fetchJobById(effectiveId);
            setJob(response.data.job);
            setIsLoading(false);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    };

    return (
        <div className="App">
            <Container>
                {isLoading ? (
                    <LoadingScreenIndividual individual={"job"}/>
                    )
                    :
                    (
                        <Row>
                            {job.map((job) => {
                                const sanitizedHtml = DOMPurify.sanitize(job.job_description); //sanitize HTML content especially when using dangerouslySetInnerHTML, to prevent potential cross-site scripting (XSS) attacks
                                return (
                                <Row key={job._id}>
                                    <Card style={{ width: "85%", margin: 'auto', borderRadius:"15px", marginBottom:"20px"}} bg={"dark"}>
                                        <Card.Body style={{color: "white", textAlign: "center"}}>
                                            <Card.Title className={"mb-2"} style={{color:"#cf6a4c"}}>
                                                <h3>
                                                    <strong>{job.job_title}</strong>
                                                </h3>
                                            </Card.Title>
                                            <Card.Subtitle className="mb-2">
                                                <OverlayTrigger
                                                    trigger="hover"
                                                    key={"companyDetails_company"}
                                                    placement={"auto"}
                                                    overlay={
                                                        <Popover id={`popover-positioned-right`}>
                                                            <Popover.Header as="h3">{`Company Profile`}</Popover.Header>
                                                            <Popover.Body>
                                                                <span>
                                                                    Redirect me to <b>{job.companyDetails.company_name}</b> profile page.
                                                                </span>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <span>
                                                        <h4><FontAwesomeIcon icon={faBuilding} /> <u><Link to={"/company/" + job.companyDetails._id}>{job.companyDetails.company_name}</Link></u></h4>
                                                    </span>
                                                </OverlayTrigger>
                                            </Card.Subtitle>
                                            <Card.Subtitle className="mb-2">
                                                <h6>
                                                    <FontAwesomeIcon icon={faLocationDot} /> {job.job_location}
                                                </h6>
                                            </Card.Subtitle>
                                            <hr/>
                                            <Row style={{textAlign:"center"}}>
                                                <Col style={{margin:"auto"}}>
                                                    <div style={{textAlign:"center", padding:"10px", paddingTop:"0px"}}>
                                                        <FontAwesomeIcon icon={faSquareCheck} /> <u>Key Features</u>
                                                    </div>
                                                    <Card.Subtitle className="mb-2">
                                                        <JobDepartment jobs={job} />
                                                        <JobExpLevel jobs={job}/>
                                                        <JobType jobs={job}/>
                                                        <JobSalary jobs={job}/>
                                                    </Card.Subtitle>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Card.Text style={{padding: isMobile ? "":"10px 50px"}}>
                                                    <div>
                                                        <h4><FontAwesomeIcon icon={faCircleInfo} /> About Job</h4>
                                                    </div>
                                                    <div style={{border: "inset #8d99ae", borderRadius: "10px", padding: "20px", color:"white", textAlign:"justify", background:"#6d6875"}}>
                                                        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                                                    </div>
                                                </Card.Text>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <ApplyToJob jobDetails = {job} companyDetails = {job.companyDetails}/>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                )
                            })}
                        </Row>
                    )}
            </Container>
        </div>
    );
};
export default MoreJobDetails;
