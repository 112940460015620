import React, {useEffect, useState} from 'react';
import '../../css/Pagination.css';
import Pagination from "react-bootstrap/Pagination";
import JobDataService from "../../services/job/jobService";
import {Col} from "react-bootstrap";

const JobsPaginationItem = ({ currentPage, setCurrentPage }) => {
    const [totalPosts, setTotalPosts] = useState(0);
    const [postsPerPage, setPostsPerPage] = useState(12);
    const totalPages = Math.ceil(totalPosts / postsPerPage);

    const prepPagination = async () => {
        try {
            let response = await JobDataService.getAllJobs(currentPage);

            // setCurrentPage(response.data.page);
            setPostsPerPage(response.data.jobsPerPage);
            setTotalPosts(response.data.totalJobs);
        } catch (error) {
            console.error("An error occurred while prepping pagination: " + error);
        }
    };

    useEffect(() => {
        prepPagination();
    }, [currentPage]);

    // Logic to generate pagination items
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage + 1}
                onClick={() => setCurrentPage(number - 1)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <div>
            <Col className="d-flex justify-content-center">
                <Pagination size={"sm"}>
                    <Pagination.Prev
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 0}
                    />
                    {items}
                    <Pagination.Next
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage >= totalPages - 1}
                    />
                </Pagination>
            </Col>
        </div>
    );
};

export default JobsPaginationItem;
