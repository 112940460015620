import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CompanyDataService from "../../../../services/company/companyService";

function Approval() {
    const [statusMessage, setStatusMessage] = useState('Processing your request...');

    // Use `useLocation` to get the query parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const company_id = queryParams.get('company_id');
    const uid = queryParams.get('uid');
    const action = queryParams.get('action');

    useEffect(() => {
        const approveRequest = async () => {
            try {
                const response = await CompanyDataService.joinCompanyApproval(
                    company_id,
                    uid,
                    action,
                );
                setStatusMessage(response.data); // Show success message from the backend
            } catch (error) {
                console.error('Error processing approval:', error);
                setStatusMessage('Failed to process the approval.');
            }
        };

        // Call the approval function
        approveRequest();
    }, [company_id, uid, action]);

    return (
        <div>
            <h1>Approval Page</h1>
            <p>{statusMessage}</p>
        </div>
    );
}

export default Approval;