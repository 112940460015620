import React, { useState, useEffect } from "react";
import CompanyDataService from "../../../services/company/companyService";
import { Col, Row, Container, Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faBuilding,
    faGlobe,
    faLocationDot,
    faIndustry,
    faPenToSquare,
    faPaperclip,
    faArrowTurnUp
} from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify';
import {Editable, EditableInput, EditablePreview, Skeleton, Stack, useToast} from "@chakra-ui/react";
import Form from "react-bootstrap/Form";
import '../../../css/BasicUpdateForm.css';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";


function UpdateCompanyProfile() {

    const [companies, setCompanies] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    const toast = useToast();
    const [profileUpdated, setProfileUpdated] = useState(false);

    // Set form fields
    const [formData, setFormData] = useState({
        company_name: '',
        company_webpage: '',
        company_location: '',
        company_industry:'',
        company_email: '',
        company_description: '',
        company_benefits: '',
        company_logo: ''
    });

    // Set initial values
    const [companyInitialVal, setCompanyInitialVal] = useState({
        company_name: formData.company_name,
        company_webpage: formData.company_webpage,
        company_location: formData.company_location,
        company_industry: formData.company_industry,
        company_email: formData.company_email,
        company_description: formData.company_description,
        company_benefits: formData.company_benefits,
        company_logo: formData.company_logo
    })

    const [toastTitleVal] = useState({
        company_name: "Company name",
        company_webpage: "Webpage",
        company_industry: "Industry",
        company_location: "Location",
        company_email: "Email",
        company_description: "Description",
        company_benefits: "Benefits",
        company_logo: "Logo"
    });


    useEffect(() => {
        // Fetch existing company profile details
        const companyProfileDetails = async () => {
            setIsLoading(true); // Switch this for Skeleton
            try {
                let response = await CompanyDataService.fetchCompanyById(id);
                setCompanies(response.data.company);
                setFormData(response.data.company);
                setCompanyInitialVal(response.data.company)
                setIsLoading(false); // Switch this for Skeleton
            } catch (error) {
                console.error("An error occurred while fetching data: " + error);
            }
        }

        companyProfileDetails();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const submitNewCompanyProfileDetails = async (e) => {
        e.preventDefault();

        if (profileUpdated) {
            const promise = new Promise(async (resolve, reject) => {
                try {
                    let response = await CompanyDataService.updateCompany(id, formData);
                    resolve();
                    setFormData(response.data.company);
                } catch (error) {
                    console.error("An error occurred while fetching data: " + error);
                    reject(error);
                }
            });

            toast.promise(promise, {
                loading: { title: 'Updating company profile...', description: 'Please wait' },
                success: { title: 'Company profile updated successfully!', description: 'Operation successful' },
                error: { title: 'Error creating company profile', description: 'Please try again' }
            });
        } else {
            toast({
                title: "Company Profile Unchanged",
                description: "It looks like there were no updates made. If you wish to make changes, please edit and submit your modifications.",
                status: 'info',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    const handleEditableSubmit = (fieldName) => (value) => {
        setFormData({ ...formData, [fieldName]: value });

        if (companyInitialVal[fieldName] !== value) {
            setProfileUpdated(true);
            if (fieldName !== 'company_description' && fieldName !== 'company_benefits') {
                toast({
                    title: toastTitleVal[fieldName],
                    description: "Value has been updated",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })
            }
        }

        if (companyInitialVal[fieldName] === value){
            if (fieldName !== 'company_description' && fieldName !== 'company_benefits') {
                toast({
                    title: toastTitleVal[fieldName],
                    description: "No change done",
                    status: 'info',
                    duration: 4000,
                    isClosable: true,
                })
            }
        }
    };

    const sanitizedHtmlCompDesc = DOMPurify.sanitize(companies.company_description); //sanitize HTML content especially when using dangerouslySetInnerHTML, to prevent potential cross-site scripting (XSS) attacks
    const sanitizedHtmlCompBen = DOMPurify.sanitize(companies.company_benefits); //sanitize HTML content especially when using dangerouslySetInnerHTML, to prevent potential cross-site scripting (XSS) attacks
    return (
        <div className="App">
            <Container>
                <Form onSubmit={submitNewCompanyProfileDetails}>
                    {isLoading ? (
                        <Stack>
                            <Skeleton height='20px' />
                            <Skeleton>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                            </Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />

                            <Skeleton height='20px' />
                            <Skeleton>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                            </Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />

                            <Skeleton height='20px' />
                            <Skeleton>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                            </Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />

                            <Skeleton height='20px' />
                            <Skeleton>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                                <div>won't be visible</div>
                            </Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />

                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />
                            <Skeleton startColor='black.500' endColor='black.500' height='20px' />
                        </Stack>
                        )
                        : (
                            <Row>
                                {companies && (
                                    <Row key={companies._id}>
                                        <Card style={{ width: isMobile ? "85%":"70%", margin: isMobile ? "auto" : 'auto', borderRadius:"15px"}} bg={"dark"}>
                                            <Card.Body style={{color: "white", textAlign: "start"}}>
                                                <Row style={{textAlign:"center"}}>
                                                    <Card.Title>
                                                        <h2>Edit Company Profile</h2>
                                                    </Card.Title>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <Card.Subtitle>
                                                        <FontAwesomeIcon icon={faBuilding} /> Company Name:
                                                        <Editable fontSize='xl'
                                                                  className={"basic-update-form"}
                                                                  defaultValue={companies.company_name}
                                                                  placeholder={"i.e. Localtalent"}
                                                                  onSubmit={handleEditableSubmit('company_name')}>
                                                            <EditablePreview />
                                                            <EditableInput />
                                                        </Editable>
                                                    </Card.Subtitle>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <Col>
                                                        <Card.Subtitle>
                                                            <FontAwesomeIcon icon={faGlobe} /> Website:
                                                            <Editable fontSize='xl'
                                                                      className={"basic-update-form"}
                                                                      defaultValue={companies.company_webpage}
                                                                      placeholder={"i.e. https://localtalent.me"}
                                                                      onSubmit={handleEditableSubmit('company_webpage')}>
                                                                <EditablePreview />
                                                                <EditableInput />
                                                            </Editable>
                                                        </Card.Subtitle>
                                                    </Col>
                                                    <Col>
                                                        <Card.Subtitle>
                                                            <FontAwesomeIcon icon={faIndustry} /> Industry:
                                                            <Editable fontSize='xl'
                                                                      className={"basic-update-form"}
                                                                      defaultValue={companies.company_industry}
                                                                      placeholder={"i.e. Job Seeking Platform"}
                                                                      onSubmit={handleEditableSubmit('company_industry')}>
                                                                <EditablePreview />
                                                                <EditableInput />
                                                            </Editable>
                                                        </Card.Subtitle>
                                                    </Col>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <Col>
                                                        <Card.Subtitle>
                                                            <FontAwesomeIcon icon={faLocationDot} /> Based on:
                                                            <Editable fontSize='xl'
                                                                      className={"basic-update-form"}
                                                                      defaultValue={companies.company_location}
                                                                      placeholder={"i.e. San Juan, PR"}
                                                                      onSubmit={handleEditableSubmit('company_location')}>
                                                                <EditablePreview />
                                                                <EditableInput />
                                                            </Editable>
                                                        </Card.Subtitle>
                                                    </Col>
                                                    <Col>
                                                        <Card.Subtitle>
                                                            <FontAwesomeIcon icon={faEnvelope} /> Email:
                                                            <Editable fontSize='xl'
                                                                      className={"basic-update-form"}
                                                                      defaultValue={companies.company_email}
                                                                      placeholder={"i.e. localtalent@jobs.com"}
                                                                      onSubmit={handleEditableSubmit('company_email')}>
                                                                <EditablePreview />
                                                                <EditableInput />
                                                            </Editable>
                                                        </Card.Subtitle>
                                                    </Col>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <Card.Text>
                                                        <div>
                                                            <h6><FontAwesomeIcon icon={faPenToSquare} /> Description</h6>
                                                        </div>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={companies.company_description}
                                                            onChange={(event, editor) => {
                                                                const content = editor.getData();
                                                                handleEditableSubmit({ target: { name: 'company_description', value: content }});
                                                            }}
                                                            config={{
                                                                placeholder: "Type in the benefits for this company...",
                                                                toolbar: [ 'undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList' ],
                                                            }}
                                                        />
                                                    </Card.Text>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <Card.Text>
                                                        <div>
                                                            <h6><FontAwesomeIcon icon={faPaperclip} /> Benefits</h6>
                                                        </div>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={companies.company_benefits}
                                                            onChange={(event, editor) => {
                                                                const content = editor.getData();
                                                                handleEditableSubmit({ target: { name: 'company_benefits', value: content }});
                                                            }}
                                                            config={{
                                                                placeholder: "Type in the benefits for this company...",
                                                                toolbar: [ 'undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList' ],
                                                            }}
                                                        />
                                                    </Card.Text>
                                                </Row>
                                                <Row style={{padding:"10px"}}>
                                                    <div className={"btn-container"} style={{display:"flex", alignItems:"center", justifyContent: "center"}}>
                                                        <Button variant={"outline-light"} size={"lg"}  type="submit">
                                                            <strong>Update Company</strong> <FontAwesomeIcon icon={faArrowTurnUp} />
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Row>
                                )}
                            </Row>
                        )}
                </Form>
            </Container>
        </div>
    );
};
export default UpdateCompanyProfile;
