import React, {useContext, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import JobDataService from "../../../services/job/jobService";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Editable, EditableInput, EditablePreview, Select, Skeleton, Stack, Switch, useToast} from '@chakra-ui/react'
import {useNavigate, useParams} from 'react-router-dom';
import companyDataService from "../../../services/company/companyService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass, faBuilding, faCircleCheck, faLocationPin, faUsersLine,
    faGear, faLink, faScaleBalanced, faPenToSquare, faBusinessTime, faArrowTurnUp, faCircleExclamation, faExclamation
} from "@fortawesome/free-solid-svg-icons";
import '../../../css/BasicUpdateForm.css';
import LoadingScreenIndividual from "../../Basic/LoadingScreenIndividual";
import { HOME } from "../../../constants/routes";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import logo from "../../../img/localtalent-light.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FirebaseContext from "../../Firebase/context";

function UpdateJobForm() {
    const { currentUser } = useContext(FirebaseContext);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const toast = useToast();
    const [companies, setCompanies] = useState([]);
    const [job, setJob] = useState([]);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [disableSalary, setDisableSalary] = useState(false); // State to handle the switch

    const toggleSalaryInput = () => {
        setDisableSalary(!disableSalary); // Toggle the state to enable/disable salary input
    };

    // Set form fields
    const [formData, setFormData] = useState({
        job: [{
            job_title: '',
            job_company_id: '',
            job_location: '',
            job_department: '',
            job_exp_level: '',
            job_apply_link: '',
            job_type: '',
            job_salary_range: '',
            job_description: ''
        }]
    });

    // Set initial values
    const [jobInitialVal, setJobInitialVal] = useState({
        job_title: formData.job_title,
        job_company_id: formData.job_company_id,
        job_location: formData.job_location,
        job_department:formData.job_department,
        job_exp_level: formData.job_exp_level,
        job_apply_link: formData.job_apply_link,
        job_type: formData.job_type,
        job_salary_range: formData.job_salary_range,
        job_description: formData.job_description
    })

    const [toastTitleVal] = useState({
        job_title: 'Job Title',
        job_company_id: 'Company',
        job_location: 'Location',
        job_department:'Department',
        job_exp_level: 'Experience Level',
        job_apply_link: 'Apply Link',
        job_type: 'Type',
        job_salary_range: 'Salary',
        job_description: 'Description'
    })

    useEffect(() => {
        if (!currentUser) {
            navigate(HOME);
        }

        // Fetch details of job post
        const jobDetails = async () => {
            setIsLoading(true); // Switch this for Skeleton
            try {
                let response = await JobDataService.fetchJobById(id);
                setJob(response.data.job);
                setFormData({ job: response.data.job });
                setJobInitialVal(response.data.job);
                setIsLoading(false); // Switch this for Skeleton
            } catch (error) {
                console.error('Error fetching job details:', error);
            }
        }

        jobDetails();

        const fetchCompanies = async () => {
            try {
                let response = await companyDataService.getAllCompanies();
                setCompanies(response.data.companies);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);


    const handleInputChange = (fieldName) => (event) => {
        const { value } = event.target; // Extract the selected or input value from the event

        setFormData((formData) => ({
            ...formData,
            job: formData.job.map((job, index) => {
                if (index === 0) {
                    return { ...job, [fieldName]: value };
                }
                return job;
            }),
        }));

        setProfileUpdated(true);

        // toast({
        //     title: toastTitleVal[fieldName],
        //     description: "Value has been updated",
        //     status: 'success',
        //     duration: 4000,
        //     isClosable: true,
        // });
    };


    const handleCKEditorChange = (fieldName) => (event, editor) => {
        const value = editor.getData();

        setFormData((formData) => ({
            ...formData,
            job: formData.job.map((job, index) => {
                if (index === 0) {
                    return { ...job, [fieldName]: value };
                }
                return job;
            }),
        }));

        setProfileUpdated(true);

        // toast({
        //     title: toastTitleVal[fieldName],
        //     description: "Value has been updated",
        //     status: 'success',
        //     duration: 4000,
        //     isClosable: true,
        // });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (profileUpdated) {
            const promise = new Promise(async (resolve, reject) => {
                try {
                    let response = await JobDataService.updateJob(id,formData);
                    resolve();
                    setFormData(response.data.job);
                } catch (error) {
                    console.error('Error creating job:', error);
                    reject(error);
                }
            });

            toast.promise(promise, {
                loading: { title: 'Updating job...', description: 'Please wait' },
                success: { title: 'Job updated successfully!', description: 'Operation successful' },
                error: { title: 'Error updating job', description: 'Please try again' }
            });
        } else {
            toast({
                title: "Job Feed Unchanged",
                description: "It looks like there were no updates made. If you wish to make changes, please edit and submit your modifications.",
                status: 'info',
                duration: 4000,
                isClosable: true,
            })
        }
    };

    return (
        <>
            <div style={{ width: isMobile ? "90%" : "70%", margin: "auto", padding: "20px" }}>
                <Form noValidate onSubmit={handleSubmit} style={{ background: "#212529", padding: "50px", borderRadius: "25px" }}>
                    {isLoading ? (
                        <LoadingScreenIndividual individual={"company"} />
                    ) : (
                        job.map((job) => (
                            <>
                                <div className={"signInLogo"} style={{textAlign: "center", background: "#212529"}}>
                                    <img src={logo} alt="Logo" style={{maxHeight: "50px", width: "auto"}}/>
                                </div>
                                <div style={{textAlign: "center", color: "white"}}>
                                    <h1><strong>Edit Job</strong></h1>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Company</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_company_id::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Company</InputGroup.Text>*/}
                                            <Form.Select
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_company_id"}
                                                required={true}
                                                name="job_company_id"
                                                value={job.job_company_id}
                                                onChange={handleInputChange("job_company_id")}
                                                placeholder="LocalTalent LLC"
                                                aria-label="job_company_id"
                                                aria-describedby="basic-addon1"
                                            >
                                                <option>--Select a Company--</option>
                                                {companies.map(company => (
                                                    <option key={company._id} value={company._id}>{company.company_name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                        {/*{errors.job_company_id && <p className={"field-required"}>Please select a valid company</p>}*/}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Job Title</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_title::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Job Title</InputGroup.Text>*/}
                                            <Form.Control
                                                id={"job_title"}
                                                required={true}
                                                name="job_title"
                                                defaultValue={job.job_title}
                                                onChange={handleInputChange("job_title")}
                                                placeholder="Node JS Dev"
                                                aria-label="job_title"
                                                aria-describedby="basic-addon1"
                                                style={{background:"#212529", color:"white"}}
                                            />
                                        </InputGroup>
                                        {/*{errors.job_title && <p className={"field-required"}>Please provide a valid job title</p>}*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Location</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_location::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                            <Form.Control
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_location"}
                                                required={true}
                                                name="job_location"
                                                defaultValue={job.job_location}
                                                onChange={handleInputChange('job_location')}
                                                placeholder="Puerto Rico"
                                                aria-label="job_location"
                                                aria-describedby="basic-addon1"
                                            />
                                        </InputGroup>
                                        {/*{errors.job_location && <p className={"field-required"}>Please provide a valid location</p>}*/}
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Department</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_department::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Department</InputGroup.Text>*/}
                                            <Form.Control
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_department"}
                                                required={true}
                                                name="job_department"
                                                defaultValue={job.job_department}
                                                onChange={handleInputChange('job_department')}
                                                placeholder="IT"
                                                aria-label="job_department"
                                                aria-describedby="basic-addon1"
                                            />
                                        </InputGroup>
                                        {/*{errors.job_department && <p className={"field-required"}>Please provide a valid department</p>}*/}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Experience Level</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_exp_level::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Experience Level</InputGroup.Text>*/}
                                            <Form.Select
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_exp_level"}
                                                required={true}
                                                name="job_exp_level"
                                                defaultValue={job.job_exp_level}
                                                onChange={handleInputChange('job_exp_level')}
                                                placeholder="Senior"
                                                aria-label="job_exp_level"
                                                aria-describedby="basic-addon1"
                                            >
                                                <option>--Select Experience Level--</option>
                                                <option key={"intern"} value={"intern"}>Internship</option>
                                                <option key={"jr"} value={"jr"}>Entry/Jr. Level</option>
                                                <option key={"mid"} value={"mid"}>Mid Level</option>
                                                <option key={"senior"} value={"senior"}>Senior Level</option>
                                            </Form.Select>
                                        </InputGroup>
                                        {/*{errors.job_exp_level && <p className={"field-required"}>Please select a valid experience level</p>}*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Apply Link</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_apply_link::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Apply Link</InputGroup.Text>*/}
                                            <Form.Control
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_apply_link"}
                                                required={true}
                                                name="job_apply_link"
                                                defaultValue={job.job_apply_link}
                                                onChange={handleInputChange('job_apply_link')}
                                                placeholder="https://www.localtalent.me/jobs"
                                                aria-label="job_apply_link"
                                                aria-describedby="basic-addon1"
                                            />
                                        </InputGroup>
                                        {/*{errors.job_apply_link && <p className={"field-required"}>Please provide a valid apply link</p>}*/}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Job Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {`
                                                    #job_type::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                `}
                                            </style>
                                            {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Job Type</InputGroup.Text>*/}
                                            <Form.Select
                                                style={{background:"#212529", color:"white"}}
                                                id={"job_type"}
                                                required={true}
                                                name="job_type"
                                                defaultValue={job.job_type}
                                                onChange={handleInputChange('job_type')}
                                                placeholder="Full-Time, Part-Time"
                                                aria-label="job_type"
                                                aria-describedby="basic-addon1"
                                            >
                                                <option>--Select Job Type--</option>
                                                <option key={"full"} value={"full"}>Full-Time</option>
                                                <option key={"part"} value={"part"}>Part-Time</option>
                                            </Form.Select>
                                        </InputGroup>
                                        {/*{errors.job_type && <p className={"field-required"}>Please select a valid job type</p>}*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Label style={{background:"#212529", color:"white"}}>Salary</Form.Label>
                                        <InputGroup className="mb-3">
                                            <style type="text/css">
                                                {disableSalary ?
                                                    `
                                                    #job_salary_range::placeholder {
                                                        color: yellow; /* Change this to your desired placeholder color */
                                                    }
                                                    `
                                                    :
                                                    `
                                                    #job_salary_range::placeholder {
                                                        color: #888; /* Change this to your desired placeholder color */
                                                    }
                                                    `
                                                }
                                            </style>
                                            <InputGroup.Text style={{background: "#212529", color: "white"}}>
                                                <Switch
                                                    type="switch"
                                                    id="disable-salary-switch"
                                                    checked={disableSalary}
                                                    onChange={toggleSalaryInput}
                                                />
                                                <span style={{marginLeft: "10px"}}>
                                                    Disable Salary
                                                </span>
                                            </InputGroup.Text>
                                            <Form.Control
                                                style={{background: "#212529", color: disableSalary ? "yellow" : "white"}}
                                                id="job_salary_range"
                                                required={!disableSalary} // Make field required only if the switch is not true
                                                name="job_salary_range"
                                                defaultValue={disableSalary ? "Do not specify salary" : job.job_salary_range}
                                                onChange={handleInputChange('job_salary_range')}
                                                placeholder={disableSalary ? "Do not specify salary" : "$60,000/year or $25/hour"}
                                                aria-label="job_salary_range"
                                                aria-describedby="basic-addon1"
                                                disabled={disableSalary} // Disable based on the switch state
                                            />
                                        </InputGroup>
                                        {disableSalary ?
                                            <InputGroup className="mb-3">
                                                <span style={{color:"#F5F5DC", border:"solid", padding:"10px", width:"100%", textAlign:"center"}}>
                                                    <FontAwesomeIcon icon={faCircleExclamation} /> Jobs with listed salaries attract more qualified candidates and receive 30% more applications. Add a salary to boost your job post’s visibility and success <FontAwesomeIcon icon={faExclamation} />
                                                </span>
                                            </InputGroup>
                                            : ""
                                        }
                                        {/*{errors.job_salary_range && <p className={"field-required"}>Please provide a valid salary</p>}*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Label style={{background: "#212529", color: "white"}}>Job Description</Form.Label>
                                        <InputGroup className="mb-3">
                                            <div style={{width: '100%'}}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={job.job_description}
                                            onChange={handleCKEditorChange("job_description")}
                                            config={{
                                                placeholder: "Type in the mission & values for this company...",
                                                toolbar: ["undo", "redo", "|", "heading", "bold", "italic", "|", "numberedList", "bulletedList"],
                                            }}
                                        />
                                            </div>
                                        </InputGroup>
                                        {/*{errors.job_description && <p className={"field-required"}>Please provide a valid despription</p>}*/}
                                    </Col>
                                </Row>
                                <div className={"btn-container"}
                                     style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Button variant={"outline-light"} size={"lg"} type="submit">
                                        <strong>Update Job</strong> <FontAwesomeIcon icon={faArrowTurnUp}/>
                                    </Button>
                                </div>
                            </>
                        ))
                    )}
                </Form>
            </div>
        </>
    );
}

export default UpdateJobForm;
