import {SimpleGrid, Skeleton, Stack} from "@chakra-ui/react";
import React from "react";

const LoadingScreenFeed = ({ feed }) => {
    let display;

    if (feed === "job") {
        display = Array.from({ length: 12 }, (_, i) => (
            <Stack width={"85%"} margin={"auto"}>
                {/* One */}
                <Stack isInline marginBottom={"30px"}>
                    <Stack width={"33%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                    <Stack width={"33%"}>
                        <SimpleGrid columns={2} spacing={1}>
                            <Skeleton startColor='blue.500' endColor='orange.500'>THIS TEXT WONT SHOW</Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500'>THIS TEXT WONT SHOW</Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500'>THIS TEXT WONT SHOW</Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500'>THIS TEXT WONT SHOW</Skeleton>
                        </SimpleGrid>
                    </Stack>
                    <Stack width={"33%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='100%'>Hi</Skeleton>
                    </Stack>
                </Stack>
            </Stack>
        ));
    } else if(feed === "company") {
        display = Array.from({ length: 12 }, (_, i) => (
            <Stack width={"85%"} margin={"auto"}>
                {/* One */}
                <Stack isInline marginBottom={"30px"}>
                    <Stack width={"33%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='50%'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='50%'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                    <Stack width={"33%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='33%'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                    <Stack width={"33%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='100%'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                </Stack>
            </Stack>
        ));
    }

    return (
        <Stack width={"85%"} margin={"auto"}>
            {display}
        </Stack>
    )
}

export default LoadingScreenFeed;