import axios from 'axios';

class AuthDataService {
    async createAuthUidRole(form) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/auth/uid-role`;

            // Send Request
            await axios.post(baseURL, form);
        } catch (error) {
            console.error("An error occurred while creating Uid-Role entry: " + error);
        }
    }
}

export default new AuthDataService();