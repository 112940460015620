import { SimpleGrid, Skeleton, Stack} from "@chakra-ui/react";
import React from "react";

const LoadingScreenIndividual = ({ individual }) => {
    let display;

    if (individual === "job") {
        display = (
            <Stack width={"85%"} margin={"auto"}>
                {/* One */}
                <Stack>
                    <Stack width={"100%"} marginBottom={"30px"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                    <Stack width={"100%"} marginBottom={"30px"}>
                        <SimpleGrid columns={3} spacing={1}>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                            <Skeleton startColor='blue.500' endColor='orange.500' height='50px'>THIS TEXT WONT SHOW</Skeleton>
                        </SimpleGrid>
                        <SimpleGrid columns={1} spacing={1} >
                            <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"33%"} margin={"auto"}>THIS TEXT WONT SHOW</Skeleton>
                        </SimpleGrid>
                    </Stack>
                    <Stack width={"100%"}>
                        <Skeleton startColor='blue.500' endColor='orange.500' height='250px'>THIS TEXT WONT SHOW</Skeleton>
                    </Stack>
                </Stack>
            </Stack>
        );
    } else if(individual === "company") {
        display = (
            <Stack width={"85%"} margin={"auto"}>
                {/* One */}
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                </Stack>
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                </Stack>
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                </Stack>
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                    <Skeleton startColor='blue.500' endColor='orange.500' height='50px' width={"50%"} />
                </Stack>
                <Skeleton startColor='blue.500' endColor='orange.500' height={"200px"} />
            </Stack>
        );
    } else if(individual === "individualCompany") {
        display = (
            <Stack width={"85%"} margin={"auto"}>
                {/* One */}
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='300px' width={"50%"}/>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='300px' width={"50%"}/>
                </Stack>
                <Stack isInline marginBottom={"30px"}>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='300px' width={"50%"}/>
                    <Skeleton startColor='blue.500' endColor='orange.500' height='300px' width={"50%"}/>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack width={"85%"} margin={"auto"}>
            {display}
        </Stack>
    )
}

export default LoadingScreenIndividual;