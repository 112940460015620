import axios from "axios";

class jobDataService {
    async getAllJobs(page = 0) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/all?page=${page}`;

            // Send Request
            const response = await axios.get(baseURL);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchJobById(id) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/job/${id}`;

            // Send Request
            const response = await axios.get(baseURL);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchUserJobs(uid, currentPage) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/my-jobs`;

            // Send Request
            const response = await axios.post(baseURL, uid, currentPage);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async listOfJobsPerCompany(id) {
        try {
            // Define URL
            const baseURL =`https://localtalent-backend.onrender.com/jobs/listing/company/${id}`;

            // Send Request
            const response = await axios.get(baseURL);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async listingOfJobsOfCompany(id) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/listing-individual-company`;

            // Send Request
            const response = await axios.post(baseURL, id);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async createJob(form) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/new/job`;

            // Send Request
            await axios.post(baseURL, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async updateJob(id, form) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/update/job/${id}`;

            // Send Request
            await axios.put(baseURL, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    // Not used for now
    async deleteJob(id) {
        try {
            await axios.delete(`https://localtalent-backend.onrender.com/jobs/delete/job/${id}`);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async jobApplicationEmail(formData) {
        try {
            // Define URL
            const baseURL = `https://localtalent-backend.onrender.com/jobs/apply`;

            // Send Request
            const response = await axios.post(baseURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response;
        } catch (error) {
            console.error("An error occurred submitting the application: " + error);
        }
    }
}

export default new jobDataService();